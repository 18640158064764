import { PoweroffOutlined, ReloadOutlined } from '@ant-design/icons'
import { ScreenModel } from '@hmedia/legenda-ds-api-client'
import { Button, Space } from 'antd'
import { FC, useState } from 'react'
import { ContentSelectorModal } from './ContentSelectorModal'
import { useRenameScreenMutation, useScreensQuery, useSetContentMutation, useWakeUpQuery } from './queries'
import ScreenItemList from './ScreenItemList'

export type Props = {
    pageTitle?: string
}

export const SCREENSVIEW_META: ViewMeta = {
    path: '/',
    title: 'Kijelzők',
}

const ScreensView: FC<Props> = (props) => {
    const screensQuiery = useScreensQuery()
    const [selectedScreen, setSelectedScreen] = useState<ScreenModel>(screensQuiery.data?.[0])
    const renameScreenMutation = useRenameScreenMutation(() => screensQuiery.refetch())
    const [contentSelectorOpened, setcontentSelectorOpened] = useState(false)
    const contentMutation = useSetContentMutation(() => {
        screensQuiery.refetch()
        setcontentSelectorOpened(false)
    })
    const wakeUpRequest = useWakeUpQuery()

    function onNameChange(id: string, name: string): void {
        renameScreenMutation.mutate({ id: id, newName: name })
    }

    return (
        <div className="dwadwadwdwa">
            <Space direction="horizontal" align="start" style={{ marginTop: '2rem', width: '100%' }}>
                <h1>Kijelzők</h1>
            </Space>
            <Space direction="vertical" style={{ width: '100%', marginTop: '2em' }} size={20}>
                <Space direction="horizontal" align="start" style={{ width: '100%' }}>
                    <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        loading={screensQuiery.isLoading}
                        onClick={() => screensQuiery.refetch()}
                        size={'large'}
                    >
                        Kijelzőlista frissítése
                    </Button>
                    {/* <Button
                        type="default"
                        icon={<PoweroffOutlined />}
                        loading={wakeUpRequest.isLoading}
                        onClick={() => wakeUpRequest.refetch()}
                        size={'large'}
                    >
                        Kijelzők bekapcsolása
                    </Button> */}
                </Space>

                {screensQuiery.data && (
                    <ScreenItemList
                        onMenuClick={(e) => {
                            setSelectedScreen(e)
                            setcontentSelectorOpened(true)
                        }}
                        items={screensQuiery.data}
                        onNameChange={onNameChange}
                    />
                )}

                {contentSelectorOpened && (
                    <ContentSelectorModal
                        onContentSelect={(content) =>
                            contentMutation.mutate({
                                contentType: content.contentType,
                                contentId: content.contentId,
                                screenId: content.screenId,
                            })
                        }
                        screen={selectedScreen}
                        visible={contentSelectorOpened}
                        onCancel={(e) => setcontentSelectorOpened(false)}
                        onClose={(e) => setcontentSelectorOpened(false)}
                    />
                )}
            </Space>
            {props.children}
        </div>
    )
}

export default ScreensView
