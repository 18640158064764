import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import { AuthorityModel, RoleModel } from '@hmedia/legenda-ds-api-client'
import { Button, Card, List, Space, Switch, Tabs, Typography } from 'antd'
import { remove } from 'lodash'
import React, { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { RoleEnum, RoleEnumConst } from '../../services/security/privileges'
import { switchChildrens } from '../uniqueDepartures/UniqueDeparturesView'
import CreateRoleModalForm, { FormValues } from './CreateRoleModalForm'
import DeleteRoleModal, { DeleteFormValues } from './DeleteRoleModal'
import {
    useAllAuthoritiesQuery,
    useAllRolesQuery,
    useCreateRoleMutation,
    useDeleteRoleMutation,
    useSetPrivilegeToRoleMutation,
} from './queries'

export type Props = {
    pageTitle?: string
}

export const AUTHORITIESVIEW_META: ViewMeta = {
    path: '/authorities',
    title: 'Jogosultságok',
}

type ActionType = 'ADD' | 'REMOVE'

const AuthoritiesView: FC<Props> = (props) => {
    const [showCreateRoleModal, setShowCreateRoleModal] = useState(false)
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false)
    const [roleToDelete, setRoleToDelete] = useState<RoleModel | undefined>(undefined)
    const queryClient = useQueryClient()
    const allRolesQuery = useAllRolesQuery()
    const allAuthoritiesQuery = useAllAuthoritiesQuery()
    const setPrivilegeToRoleMutation = useSetPrivilegeToRoleMutation((data: RoleModel) => {
        allRolesQuery.refetch()
    })
    const createRoleMutation = useCreateRoleMutation((data: RoleModel) => {
        setShowCreateRoleModal(false)
        allRolesQuery.refetch()
        setActiveTabKey(`${data.id}`)
    })

    const deleteRoleMutation = useDeleteRoleMutation(() => {
        setShowDeleteRoleModal(false)
        allRolesQuery.refetch()
        setActiveTabKey(allRolesQuery.data?.[0]?.id.toString())
    })

    const [activeTabKey, setActiveTabKey] = useState<string>()
    const isControlDisabled = (role: RoleEnum): boolean => RoleEnum.ROLE_ADMIN === role

    const onRoleChange = (role: RoleModel, authority: AuthorityModel, actionType: ActionType) => {
        let privilegeIds = role.authorities.map((aut) => aut.id)
        switch (actionType) {
            case 'ADD':
                privilegeIds.push(authority.id)
                break
            case 'REMOVE':
                remove(privilegeIds, (item) => item == authority.id)
                break
            default:
                break
        }

        setPrivilegeToRoleMutation.mutate({ rolename: role.name, privilegeIds: privilegeIds })
    }

    const onFormSubmit = (values: FormValues) => {
        createRoleMutation.mutate({ title: values.title })
    }

    const onRoleDelete = (values: DeleteFormValues) => {
        deleteRoleMutation.mutate({ roleToDelete: values.role, replacementRole: values.replacementRole })
    }

    const footerWarning = (
        <p style={{ marginTop: '2rem' }}>
            Az itt végrehajtott módosítások csak az adott jogkörrel rendelkező dolgozók következő belépésekor lesznek
            érvényben.
        </p>
    )

    return (
        <div style={{ width: '100%' }}>
            <h1 style={{ marginTop: '2rem' }}>Felhasználók</h1>
            <Space direction="horizontal">
                <Button onClick={() => setShowCreateRoleModal(true)} type="primary" icon={<PlusOutlined />}>
                    Új jogkör hozzáadása
                </Button>
            </Space>
            <Tabs
                defaultActiveKey={allRolesQuery.data?.[0].id.toString()}
                type="line"
                onChange={(activeKey) => {
                    setActiveTabKey(activeKey)
                    // return queryClient.invalidateQueries(['departures', activeKey])
                }}
                style={{
                    marginTop: '1rem',
                    backgroundColor: 'white',
                    padding: '1rem',
                    width: '100%',
                    minHeight: '80vh',
                }}
                activeKey={activeTabKey}
            >
                {allRolesQuery.data?.map((role: RoleModel) => (
                    <Tabs.TabPane tab={`${role.title}`} key={role.id}>
                        <List
                            header={<h3>Hozzáférés az oldalakhoz</h3>}
                            dataSource={allAuthoritiesQuery.data}
                            footer={footerWarning}
                            renderItem={(item: AuthorityModel) => (
                                <List.Item>
                                    <Space direction="horizontal">
                                        <Switch
                                            disabled={isControlDisabled(role.name as RoleEnum)}
                                            loading={allAuthoritiesQuery.isFetching || allAuthoritiesQuery.isLoading}
                                            checked={Object.values(role.authorities.map((vl) => vl.privilege)).includes(
                                                item.privilege,
                                            )}
                                            {...switchChildrens}
                                            onChange={(bool) => {
                                                onRoleChange(role, item, bool ? 'ADD' : 'REMOVE')
                                            }}
                                        />
                                        <Typography.Text>{item.title}</Typography.Text>
                                    </Space>
                                </List.Item>
                            )}
                        />
                        {![
                            RoleEnumConst.ROLE_ADMIN.value.toString(),
                            RoleEnumConst.ROLE_USER.value.toString(),
                        ].includes(role.name) && (
                            <Card type="inner" title={`"${role.title}" jogkör törlése`}>
                                <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteFilled />}
                                    onClick={() => {
                                        setRoleToDelete(role)
                                        setShowDeleteRoleModal(true)
                                    }}
                                >
                                    Törlés ...
                                </Button>
                            </Card>
                        )}
                    </Tabs.TabPane>
                ))}
            </Tabs>
            {showCreateRoleModal && (
                <CreateRoleModalForm
                    visible={showCreateRoleModal}
                    onCreate={onFormSubmit}
                    onCancel={() => setShowCreateRoleModal(false)}
                />
            )}
            {showDeleteRoleModal && (
                <DeleteRoleModal
                    roleToDelete={roleToDelete}
                    visible={showDeleteRoleModal}
                    onCreate={onRoleDelete}
                    onCancel={() => setShowDeleteRoleModal(false)}
                />
            )}
        </div>
    )
}

export default AuthoritiesView
