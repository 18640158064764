

/**
 *
 * @export
 * @interface InformDTO
 */
export interface InformDTO {
  /**
   *
   * @type {number}
   * @memberof InformDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof InformDTO
   */
  name: string
  /**
   *
   * @type {InformTypeConstantDTO}
   * @memberof InformDTO
   */
  type: InformTypeConstantDTO
  /**
   *
   * @type {string}
   * @memberof InformDTO
   */
  title: string | null
  /**
   *
   * @type {string}
   * @memberof InformDTO
   */
  contentJSON: string
  /**
   *
   * @type {string}
   * @memberof InformDTO
   */
  contentHTML: string
  /**
   *
   * @type {string}
   * @memberof InformDTO
   */
  updatedAt: string
  /**
   *
   * @type {boolean}
   * @memberof InformDTO
   */
  isTodayShown: boolean
  /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InformModel
     */
  contentMap: { [key: string]: string; };
}

export const InformTypeConstantDTO = {
  INFO: { value: 'INFO', name: 'Infó' },
  ACTUALITY: { value: 'ACTUALITY', name: 'Aktualitás' },
} as const
export type InformTypeConstantDTO = keyof typeof InformTypeConstantDTO

export const getInformTypeName = (inform: InformTypeConstantDTO): String => {
  switch (inform) {
    case InformTypeConstantDTO.INFO.value: return InformTypeConstantDTO.INFO.name
    case InformTypeConstantDTO.ACTUALITY.value: return InformTypeConstantDTO.ACTUALITY.name
    default: return inform
  }
}