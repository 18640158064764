import * as yup from 'yup'
import { FC } from 'react'
import { Input, Modal, Form, InputNumber, Select, Drawer, Spin, Button, Col, Row, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Cruise, TicketPriceDTO, TicketPriceDTOTypeEnum } from '@hmedia/legenda-ds-api-client'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
const { Option } = Select

export type FormValues = {
    isPublished: boolean
    name: string
    cruiseId: string
    price: number
    index: number
    ticketType: TicketPriceDTOTypeEnum
}
interface TicketPriceFormProps {
    visible: boolean
    cruises: Cruise[]
    ticket?: TicketPriceDTO
    onCreate?: (values: FormValues) => void
    onCancel?: () => void
}

const requiredMessage = 'Kötelező megadni'
const moreThanMessage = 'Nagyobb legyen, mint ${more}'

const requiredRule = { required: true, message: requiredMessage }

const TicketPriceEditorForm: FC<TicketPriceFormProps> = ({ visible, ticket, onCancel, onCreate, cruises }) => {
    const [form] = useForm()
    const initialValues: FormValues = {
        isPublished: ticket?.isPublished || false,
        name: ticket?.name,
        cruiseId: ticket?.cruise.internalName,
        price: Number(ticket?.priceEUR),
        index: ticket?.index || 0,
        ticketType: ticket?.type,
    }

    const formButtons = (
        <Row justify="end">
            <Col>
                <Space>
                    <Button
                        onClick={(_evt): void => {
                            form.resetFields()
                            onCancel()
                        }}
                    >
                        Mégsem
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    onCreate(values)
                                    form.resetFields()
                                })
                                .catch((errorInfo) => {
                                    const desc = errorInfo?.message || errorInfo.errorFields?.map((err) => {
                                        return err.errors.join('/n/r')
                                    })
                                })
                        }}
                    >
                        Beküldés
                    </Button>
                </Space>
            </Col>
        </Row>
    )

    return (
        <>
            <Spin>
                <Drawer
                    title={ticket ? `${initialValues.name} szerkesztése` : 'Jegyár hozzáadása'}
                    placement={'right'}
                    width={'500px'}
                    visible={visible}
                    size="large"
                    // extra={formButtons}
                    onClose={onCancel}
                >
                    <Form layout="vertical" form={form} initialValues={initialValues} autoComplete="off">
                        <Form.Item label="Jegytípus" name="ticketType" rules={[requiredRule]}>
                            <Select style={{ width: '200px' }} placeholder="Válassz jegytípust">
                                <Option key={TicketPriceDTOTypeEnum.NORMAL} value={TicketPriceDTOTypeEnum.NORMAL}>
                                    általános
                                </Option>
                                <Option key={TicketPriceDTOTypeEnum.ADULT} value={TicketPriceDTOTypeEnum.ADULT}>
                                    felnőtt jegy
                                </Option>
                                <Option key={TicketPriceDTOTypeEnum.BPCARD} value={TicketPriceDTOTypeEnum.BPCARD}>
                                    BP kártya
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Jegy neve" name="name" rules={[requiredRule]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="EUR ár (2 tizedesig)!" name="price" rules={[requiredRule]}>
                            <InputNumber min={0.0} max={999.99} />
                        </Form.Item>
                        <Space direction="horizontal">
                            <Form.Item label="Szolgáltatás" name="cruiseId" rules={[requiredRule]}>
                                <Select
                                    style={{ width: '200px' }}
                                    placeholder="Válassz szolgáltatást"
                                    disabled={ticket?.id !== undefined}
                                >
                                    {cruises?.map((cruise) => {
                                        return (
                                            <Option key={cruise.internalName} value={cruise.internalName}>
                                                {cruise.displayNameMap['en']}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Sorrend" name="index" rules={[]}>
                                <InputNumber min={0} max={99} precision={0} step={1} keyboard={true} />
                            </Form.Item>
                        </Space>
                    </Form>
                    {formButtons}
                </Drawer>
            </Spin>
        </>
    )
}

export default TicketPriceEditorForm
