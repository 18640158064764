import { RunnableContentDTO, ScreenModelContentTypeEnum } from "@hmedia/legenda-ds-api-client";
import { useMutation, useQuery } from "react-query";
import API from "../../services/DSApiService";

const wakeUpScreens = async () => await API.wakeUpScreens()
const wakeUpScreenByMAC = async (mac: string) => await API.wakeUpScreenByMAC(mac)
const getScreens = async () => (await API.getScreens()).data
const getRunnableContentList = async () => (await API.getRunnableContentList()).data
const renameScreen = async (id: string, newName: string) => (await API.renameScreen(id, newName)).data
const setContent = async (mutation: ContentMutation) => API.setScreenContent(mutation.screenId,
  mutation.contentType as unknown as ScreenModelContentTypeEnum, mutation.contentId
)

export const useScreensQuery = () => useQuery(['screens'], () => getScreens())
export const useWakeUpQuery = () => useQuery(['screens-wake-all'], () => wakeUpScreens(), { enabled: false })

export const useRunnableContentQuery = () => useQuery(['runnableContents'], () => getRunnableContentList())

export const useRenameScreenMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((params: RenameScreenMutation) => renameScreen(params.id, params.newName), { onSuccess: onSuccess, onError: onError })

export const useWakeUpScreenByMACMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((params: WakeUpByMacMutation) => wakeUpScreenByMAC(params.mac), { onSuccess: onSuccess, onError: onError })

export const useSetContentMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((mutation: ContentMutation) => setContent(mutation), { onSuccess: onSuccess, onError: onError })

type RenameScreenMutation = { id: string, newName: string }
type WakeUpByMacMutation = { mac: string }

export type ContentMutation = Pick<RunnableContentDTO, 'contentType' | 'contentId'> & { screenId: string }