import { BrowserHistory } from 'history'
import { FC, useContext, useLayoutEffect, useRef } from 'react'
import { UNSAFE_NavigationContext } from 'react-router-dom'
import styled from 'styled-components'

const Scrollable = styled.div`
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
`
export const ScrollableContent: FC = (props) => {
    const navigation = useContext(UNSAFE_NavigationContext).navigator as BrowserHistory
    const ref = useRef<HTMLDivElement>()

    useLayoutEffect(() => {
        if (navigation) {
            const unlisten = navigation.listen((locationListener) => {
                ref.current.scrollTo(0, 0)
            })
            return () => unlisten()
        }
    }, [navigation])

    return <Scrollable ref={ref}>{props.children}</Scrollable>
}
