import { DeleteOutlined } from '@ant-design/icons'
import { MediaFileDTO } from '@hmedia/legenda-ds-api-client'
import { Button, Image, Space, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { TableRowSelection } from 'antd/lib/table/interface'
import { FC, Key, useState } from 'react'
import { useQueryClient } from 'react-query'
import MediaFileEditorDrawerForm from './MediaFileEditorDrawerForm'
import { useMediaFilesQuery } from './queries'

export type Props = {
    pageTitle?: string
}

export const MEDIAFILESVIEW_META: ViewMeta = {
    path: '/mediafiles',
    title: 'Médiafájlok',
}

const MediafileView: FC<Props> = (props) => {
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const queryClient = useQueryClient()
    const mediaFilesQuery = useMediaFilesQuery()
    const tableCols: ColumnType<MediaFileDTO>[] = [
        {
            title: '',
            key: 'thumbnail',
            dataIndex: 'thumbnailURL',
            width: '150px',
            align: 'center',
            render: (thumbnailURL: string, mf: MediaFileDTO) => {
                return (
                    <Image
                        width={150}
                        src={thumbnailURL}
                        preview={{
                            src: mf.fileURL,
                        }}
                    />
                )
            },
        },
        {
            title: 'megnevezés',
            key: 'title',
            dataIndex: 'title',
            align: 'left',
            render: (title: string, mf: MediaFileDTO) => {
                return <span>{title}</span>
            },
        },
        {
            title: 'fájl',
            key: 'fileName',
            dataIndex: 'filename',
        },
        {
            title: '',
            key: 'actions',
            dataIndex: 'filename',
            render: (filename: string, mf: MediaFileDTO) => (
                <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={(e) => {
                        mediaFilesQuery.refetch()
                    }}
                ></Button>
            ),
        },
    ]
    return (
        <>
            <Space
                direction="vertical"
                align="start"
                style={{ marginTop: '2rem', width: '100%', justifyContent: 'space-between' }}
            >
                <h1>Médiafájlok</h1>
                <Space>
                    <Button onClick={(e) => setIsEditorOpen(true)}>Médiafájl hozzáadása</Button>
                    <Button onClick={(e) => mediaFilesQuery.refetch()}>Médiafájlok frissítése</Button>
                </Space>

                <Table<MediaFileDTO>
                    loading={mediaFilesQuery.isLoading}
                    dataSource={mediaFilesQuery.data}
                    rowKey={'fileName'}
                    columns={tableCols}
                    size="middle"
                    style={{ width: '1000px', marginTop: '1rem' }}
                />
            </Space>
            {isEditorOpen && (
                <MediaFileEditorDrawerForm
                    visible={isEditorOpen}
                    onCreate={(data: MediaFileDTO) => {
                        setIsEditorOpen(false)
                        queryClient.invalidateQueries('mediafiles')
                    }}
                    onClose={(e) => setIsEditorOpen(false)}
                    onCancel={(e) => setIsEditorOpen(false)}
                />
            )}
        </>
    )
}

export default MediafileView
