import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import hu from "dayjs/locale/hu";

dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekYear)
dayjs.extend(weekOfYear)


dayjs.locale(hu)
dayjs.tz.setDefault('Europe/Budapest')

const DateFormatType = {
  longDateTime: 'll HH:mm',
  shortDateTime: 'YYYY.MM.DD. HH:mm'
} as const
declare type DateFormatType = keyof typeof DateFormatType

export { dayjs, DateFormatType }

export * as react from './react/actionCreator'

