import { Button } from 'antd'
import styled from 'styled-components'

export const LoginView = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: white;
    }
`

export const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 20vw;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
`

export const LoginTitle = styled.div`
    font-weight: 800;
    color: white;
    font-size: 2em;
    margin-bottom: 1em;
`

export const LoginFootNote = styled.p`
    margin-top: 1em;
`

export const LoginSubmitButton = styled(Button)`
    width: 100%;
    font-weight: 800;
`

export const VersionNumber = styled.span`
    color: rgba(255, 255, 255, 0.5);
    align-self: flex-end;
    justify-self: flex-end;
`
