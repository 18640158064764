import { AppSettingDTO, TicketPriceCreateRequestTypeEnum, TicketPriceDTO, TicketPriceStatusUpdateRequest, TicketPriceUpdateRequestTypeEnum } from "@hmedia/legenda-ds-api-client";
import { useMutation, useQuery } from "react-query";
import API from "../../services/DSApiService";

/**
 * Functions
 */

const getAppSettings = async () => (await API.getAppSettings()).data

const getExchangeRateSettings = async () => (await API.getExchangeRateSettings()).data

const updateEURExchangeRate = async (settings: AppSettingDTO[]) => await API.updateAppSettings(settings)


/**
 * Mutations, Queries
 */
export const useAppSettingsQuery = () => useQuery(["appSettings"], () => getAppSettings())

export const useUpdateAppSettingsMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((settings: AppSettingDTO[]) => updateEURExchangeRate(settings), { onSuccess: onSuccess, onError: onError })


export const useExchangeRateSettingsQuery = () => useQuery(["exchrate-settings"], () => getExchangeRateSettings())