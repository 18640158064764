import { ReloadOutlined } from '@ant-design/icons'
import {
    AppSettingDTOIdEnum,
    TicketPriceCreateRequestTypeEnum,
    TicketPriceDTO,
    TicketPriceUpdateRequestTypeEnum,
} from '@hmedia/legenda-ds-api-client'
import { Button, Space, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import API from '../../services/DSApiService'
import { useCruisesQuery } from '../timetable/queries'
import {
    useAppSettingsQuery,
    useCreateTicketPriceMutation,
    useTicketPricesQuery,
    useUpdateEURExchangeRateMutation,
    useUpdateTicketPriceMutation,
} from './queries'
import TicketPriceEditorForm, { FormValues } from './TicketPriceEditor'
import TicketPriceTablesContainer from './TicketPriceTablesContainer'

export type Props = {
    pageTitle?: string
}

export const TICKETPRICESVIEW_META: ViewMeta = {
    path: '/ticketprices',
    title: 'Jegyárak',
}

const TicketPricesView: FC<Props> = (props) => {
    const [eurRate, setEurRate] = useState<number>(null)
    const [editingTicket, setEditingTicket] = useState<TicketPriceDTO | undefined>(undefined)
    const [drawerIsVisible, setDrawerIsVisible] = useState(false)

    const cruisesQuery = useCruisesQuery()
    const ticketPricesQuery = useTicketPricesQuery(!!cruisesQuery.data)
    const appSettingsQuery = useAppSettingsQuery()

    const updatePriceMutation = useUpdateTicketPriceMutation((data) => {
        updatePrices()
    })
    const updateEURExchangeRateMutation = useUpdateEURExchangeRateMutation(() => updatePrices())

    const createTicketPriceMutation = useCreateTicketPriceMutation((data) => {
        updatePrices()
    })

    const updatePrices = () => {
        setDrawerIsVisible(false)
        cruisesQuery.refetch()
        appSettingsQuery.refetch()
        ticketPricesQuery.refetch()
    }

    const onDelete = (priceId: number) => {
        API.deleteTicketPrices([priceId]).then(() => {
            updatePrices()
        })
    }

    const onCreate = (values: FormValues) => {
        if (editingTicket && editingTicket !== null) {
            updatePriceMutation.mutate({
                name: values.name,
                id: editingTicket.id,
                index: values.index,
                priceEUR: values.price.toString(),
                type: values.ticketType as unknown as TicketPriceUpdateRequestTypeEnum,
            })
        } else {
            createTicketPriceMutation.mutate({
                name: values.name,
                cruiseId: values.cruiseId,
                price: values.price.toString(),
                ticketType: values.ticketType as unknown as TicketPriceCreateRequestTypeEnum,
                index: values.index,
            })
        }
    }

    useEffect(() => {
        const rate = appSettingsQuery.data?.find((d) => d.id === AppSettingDTOIdEnum.EURO_EXCHANGE_RATE)?.value
        setEurRate(Number(rate))
    }, [appSettingsQuery.data])

    return (
        <Spin spinning={updateEURExchangeRateMutation.isLoading || ticketPricesQuery.isFetching}>
            <Space
                direction="horizontal"
                align="start"
                style={{ marginTop: '2rem', width: '100%', justifyContent: 'space-between' }}
            >
                <h1>Jegyárak</h1>
            </Space>
            <Space direction="vertical" align="start" style={{ marginTop: '1rem', width: '100%' }}>
                <Space direction="horizontal">
                    <Button onClick={() => updatePrices()} icon={<ReloadOutlined />}>
                        Frissítés
                    </Button>
                    <Button disabled onClick={() => {}}>
                        Jegyárak szinkronizálása
                    </Button>
                    <Button onClick={() => setDrawerIsVisible(true)}>Új jegyár hozzáadása</Button>
                    {eurRate && (
                        <div style={{ marginLeft: '3rem' }}>
                            Használt € árfolyam: <strong>{eurRate} Ft</strong>
                        </div>
                    )}
                </Space>
                {ticketPricesQuery.data && (
                    <TicketPriceTablesContainer
                        data={ticketPricesQuery.data}
                        cruises={cruisesQuery.data}
                        onDelete={onDelete}
                        onStatusUpdate={() => {
                            ticketPricesQuery.refetch()
                        }}
                        openEditForm={(ticket) => {
                            setEditingTicket(ticket)
                            setDrawerIsVisible(true)
                        }}
                        style={{ width: '80vw', minWidth: '700px' }}
                    />
                )}
            </Space>

            {drawerIsVisible && (
                <TicketPriceEditorForm
                    visible={drawerIsVisible}
                    cruises={cruisesQuery.data}
                    ticket={editingTicket}
                    onCreate={onCreate}
                    onCancel={() => {
                        setDrawerIsVisible(false)
                        setEditingTicket(null)
                    }}
                />
            )}
        </Spin>
    )
}

export default TicketPricesView
