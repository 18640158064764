import { useMutation } from "react-query";
import API from "../../services/DSApiService";

const updateDescriptions = (params: DeparturesDescriptionMutationRequest) =>
  API.updateDepartureDescription({ cruiseId: params.cruiseId, startAt: params.startAt, text: params.text, includesUnique: params.includesUnique })

const updateUniqueDescription = (params: UniqueDeparturesDescriptionMutationRequest) =>
  API.updateUniqueDepartureDescription({ departureId: params.departureId, text: params.text, startDate: params.startDate })

/**
 * Mutations, Queries
 */
export const useDescriptionsMutation = (onSuccess?: (data) => void, onError?: (error) => void) => useMutation((params: DeparturesDescriptionMutationRequest) => updateDescriptions(params), { onSuccess: onSuccess, onError: onError });
export const useUniqueDescriptionsMutation = (onSuccess?: (data) => void, onError?: (error) => void) => useMutation((params: UniqueDeparturesDescriptionMutationRequest) => updateUniqueDescription(params), { onSuccess: onSuccess, onError: onError });

type DeparturesDescriptionMutationRequest = {
  cruiseId: number, startAt: string, text: string, includesUnique: boolean
}
type UniqueDeparturesDescriptionMutationRequest = {
  departureId: number, text: string, startDate: string
}