import { Button, Col, Drawer, Form, Input, Row, Space, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { FC, useEffect, useState } from 'react'
import { Descendant, Editor } from 'slate'
import SlateEditor, { emptyNode } from '../../components/SlateEditor/SlateEditor'
import { InformDTO, InformTypeConstantDTO } from './models'
// import { useCreateMediaFileMutation, useUpdateMediaFileMutation, useUploadFileMutation } from './queries'

import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { InformPreview } from '../../components/InformPreview/InformPreview'
import { slateSerializer } from '../../components/SlateEditor/serializer'
import { InformModelTypeEnum } from '@hmedia/legenda-ds-api-client'
import { toast } from 'react-toastify'

export type FormValues = {
    name?: string
    title?: string | null
    type: InformTypeConstantDTO
    isTodayShown: boolean
    content?: Descendant[]
}

interface InformEditorFormProps {
    inform?: InformDTO
    type: InformModelTypeEnum
    visible: boolean
    onCreate?: (formValues: FormValues) => void
    onCancel?: (e) => void
    onClose?: (e) => void
}

const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const InformEditorDrawerForm: FC<InformEditorFormProps> = ({ visible, inform, type, onCancel, onCreate, onClose }) => {
    const isActualityType = type === 'ACTUALITY'
    const jsonContent = inform?.contentJSON ? JSON.parse(inform?.contentJSON) : emptyNode
    const initialValues: FormValues = inform
        ? {
              name: inform?.name,
              title: inform?.title,
              type: type,
              isTodayShown: inform?.isTodayShown,
              content: jsonContent,
            }
            : { type: type, isTodayShown: false }
            const [titlePreview, setTitlePreview] = useState(undefined)
    const maxLimitInCharacters = isActualityType ? 450 : 300
    const [currentCharLength, setcurrentCharLength] = useState(0)
    const [htmlPreview, setHtmlPreview] = useState<Descendant[]>([])
    const [isTodayShownPreview, setisTodayShownPreview] = useState<boolean>(initialValues.isTodayShown)

    useEffect(() => {
        setTitlePreview(inform?.title)
        setHtmlPreview(jsonContent)

        return () => {}
    }, [])

    const [form] = useForm<FormValues>()

    const formButtons = (
        <Row justify="end">
            <Col>
                <Space>
                    <Button
                        onClick={(_evt): void => {
                            form.resetFields()
                            onCancel(_evt)
                        }}
                    >
                        mégsem
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    form.resetFields()
                                    onCreate({
                                        name: values.name,
                                        title: values.title,
                                        type: values.type,
                                        isTodayShown: values.isTodayShown,
                                        content: values.content,
                                    })
                                })
                                .catch((errorInfo: ValidateErrorEntity<FormValues>) => {
                                    const desc = errorInfo.errorFields.map((err) => {
                                        return err.errors.join('/n/r')
                                    })
                                })
                        }}
                    >
                        Mentés
                    </Button>
                </Space>
            </Col>
        </Row>
    )

    const onEditorChange = (content: Descendant[], charLength: number, maxLength: number) => {
        setHtmlPreview(content)
        setcurrentCharLength(charLength)
        return content
    }

    const onTodayCheckboxChange = (evt: CheckboxChangeEvent) => {
        setisTodayShownPreview(evt.target.checked)
    }

    const drawerTitleByType = isActualityType ? 'Aktualitás' : 'Infó'

    return (
        <>
            <Spin spinning={false}>
                <Drawer
                    title={inform ? `${drawerTitleByType} szerkesztése` : `${drawerTitleByType} létrehozása`}
                    placement={'right'}
                    width={'1200px'}
                    visible={visible}
                    size="large"
                    extra={formButtons}
                    onClose={onClose}
                >
                    <Form layout="vertical" form={form} initialValues={initialValues} autoComplete="off">
                        <Space direction="horizontal" size={'large'} align="baseline" style={{ width: '100%' }}>
                            <Form.Item label="Admin elnevezés" name="name" rules={[requiredRule]}>
                                <Input width={200} />
                            </Form.Item>
                            <Form.Item
                                label="Tartalom címe (opcionális)"
                                name="title"
                            >
                                <Input
                                    showCount
                                    maxLength={30}
                                    style={{ width: '400px' }}
                                    onChange={(vall) => {
                                        setTitlePreview(form.getFieldValue('title'))
                                    }}
                                />
                            </Form.Item>
                            {isActualityType && (
                                <Form.Item label="Dátum" name="isTodayShown" valuePropName="checked">
                                    <Checkbox onChange={onTodayCheckboxChange}>
                                        mutassa a cím mellett a mai dátumot
                                    </Checkbox>
                                </Form.Item>
                            )}
                        </Space>

                        <Form.Item
                            label={`Tartalom szövege - ${currentCharLength} / ${maxLimitInCharacters} karakter`}
                            name="content"
                            getValueFromEvent={onEditorChange}
                            valuePropName="data"
                            rules={[requiredRule]}
                        >
                            <SlateEditor
                                type={type}
                                onChange={onEditorChange}
                                maxLength={maxLimitInCharacters}
                                onTextLimitReached={(limit) => {}}
                                initialValue={initialValues.content}
                            />
                        </Form.Item>
                    </Form>
                    <p>Előnézet</p>
                    <InformPreview
                        type={type}
                        html={slateSerializer(htmlPreview)}
                        title={titlePreview}
                        isTodayShown={isTodayShownPreview}
                    />
                    {formButtons}
                </Drawer>
            </Spin>
        </>
    )
}

export default InformEditorDrawerForm
