import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'

if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_FAKE_API_ENABLED === 'true') {
        // ;(async () => {
        //     import('./services/FakeAPIService')
        // })()
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
)
