import { TicketPriceCreateRequestTypeEnum, TicketPriceDTO, TicketPriceStatusUpdateRequest, TicketPriceUpdateRequestTypeEnum } from "@hmedia/legenda-ds-api-client";
import { useMutation, useQuery } from "react-query";
import API from "../../services/DSApiService";

/**
 * Functions
 */

const updateStatus = async (id: number, isPublished: boolean, index: number) =>
  (await API.updateTicketPriceStatus(id, isPublished, index)).data

const updateTicketPrice = async (params: UpdateTicketPriceMutationRequest) =>
  (await API.updateTicketPrice({
    id: params.id, index: params.index, name: params.name, priceEUR: params.priceEUR, type: params.type,
  })).data

const updateTicketPriceIndexes = async (params: UpdateTicketPriceIndexesRequest) =>
  await API.updateTicketPriceIndexes({
    requests: params.requests.map(param => ({
      id: param.id,
      index: param.index,
      isPublished: true
    })) as unknown as TicketPriceStatusUpdateRequest[]
  })

const getTicketPrices = async () => (await API.getTicketPrices()).data

const getAppSettings = async () => (await API.getAppSettings()).data

const updateEURExchangeRate = async (rate: number) => (await API.updateEURExchangeRate(rate)).data

const createTicketPrice = async (params: CreateTicketPriceMutationRequest) =>
  (await API.createTicketPrice(params.name, params.cruiseId, params.price, params.ticketType, params.index,)).data

/**
 * Mutations, Queries
 */

export const useUpdateStatusMutation = (onSuccess?: (data: TicketPriceDTO) => void, onError?: (error) => void) =>
  useMutation((req: UpdateStatusMutationRequest) =>
    updateStatus(req.id, req.isPublished, req.index), { onSuccess: onSuccess, onError: onError })


export const useUpdateTicketPriceMutation = (onSuccess?: (data: TicketPriceDTO) => void, onError?: (error) => void) =>
  useMutation((req: UpdateTicketPriceMutationRequest) =>
    updateTicketPrice(req), { onSuccess: onSuccess, onError: onError })

export const useUpdateTicketPriceIndexes = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((req: UpdateTicketPriceIndexesRequest) =>
    updateTicketPriceIndexes(req), { onSuccess: onSuccess, onError: onError })


export const useTicketPricesQuery = (waitFor: boolean = true) => useQuery(["ticketprices"], () => getTicketPrices(),
  { enabled: waitFor })

export const useAppSettingsQuery = () => useQuery(["appSettings"], () => getAppSettings())

export const useUpdateEURExchangeRateMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((rate: number) => updateEURExchangeRate(rate), { onSuccess: onSuccess, onError: onError })


export const useCreateTicketPriceMutation = (onSuccess?: (data: TicketPriceDTO) => void, onError?: (error) => void) =>
  useMutation((params: CreateTicketPriceMutationRequest) => createTicketPrice(params), { onSuccess: onSuccess, onError: onError })


/**
 * Types
 */

type UpdateStatusMutationRequest = { id: number, isPublished?: boolean, index?: number }

type UpdateTicketPriceMutationRequest = {
  name: string,
  id: number,
  index: number,
  priceEUR: string,
  type: TicketPriceUpdateRequestTypeEnum
}
type CreateTicketPriceMutationRequest = {
  name: string,
  cruiseId: string,
  price: string,
  ticketType: TicketPriceCreateRequestTypeEnum,
  index: number,
}

type UpdateTicketPriceIndexRequest = Omit<UpdateTicketPriceMutationRequest, 'name' | 'priceEUR' | 'type'>

type UpdateTicketPriceIndexesRequest = {
  requests: UpdateTicketPriceIndexRequest[]
}