import { InformModelTypeEnum } from '@hmedia/legenda-ds-api-client'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ContentsView, { CONTENTSVIEW_META } from '../../domains/contents/ContentsView'
import InformView, { ACTUALITYVIEW_META, INFOVIEW_META } from '../../domains/inform/InformView'
import MediafileView, { MEDIAFILESVIEW_META } from '../../domains/mediafile/MediafilePage'
import ScreensView, { SCREENSVIEW_META } from '../../domains/screens/ScreensView'
import SettingsView, { SETTINGSVIEW_META } from '../../domains/settings/SettingsView'
import TicketPricesView, { TICKETPRICESVIEW_META } from '../../domains/ticketPrices/TicketPricesPage'
import TimetableView, { TIMETABLEVIEW_META } from '../../domains/timetable/TimetableView'
import UniqueDeparturesView, { UNIQUEDEPARTURESVIEW_META } from '../../domains/uniqueDepartures/UniqueDeparturesView'
import AuthoritiesView, { AUTHORITIESVIEW_META } from '../../domains/users/AuthoritiesView'
import UsersView, { USERSVIEW_META } from '../../domains/users/UsersView'

const Routing = () => {
    return (
        <Routes>
            <Route path={SCREENSVIEW_META.path} element={<ScreensView />} />
            <Route path={CONTENTSVIEW_META.path} element={<ContentsView />} />
            <Route path={TICKETPRICESVIEW_META.path} element={<TicketPricesView />} />
            <Route path={TIMETABLEVIEW_META.path} element={<TimetableView />} />
            <Route path={UNIQUEDEPARTURESVIEW_META.path} element={<UniqueDeparturesView />} />
            <Route path={MEDIAFILESVIEW_META.path} element={<MediafileView />} />
            <Route path={INFOVIEW_META.path} element={<InformView type={InformModelTypeEnum.INFO} />} />
            <Route path={ACTUALITYVIEW_META.path} element={<InformView type={InformModelTypeEnum.ACTUALITY} />} />
            <Route path={USERSVIEW_META.path} element={<UsersView />} />
            <Route path={AUTHORITIESVIEW_META.path} element={<AuthoritiesView />} />
            <Route path={SETTINGSVIEW_META.path} element={<SettingsView />} />
        </Routes>
    )
}

export default Routing
