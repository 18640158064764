import { AddUniqueDepartureRequestGateEnum, Cruise, DepartureModelGateEnum } from '@hmedia/legenda-ds-api-client'
import { DatePicker, TimePicker } from '@leg/ds-react'
import { Button, Checkbox, CheckboxOptionType, Col, Divider, Drawer, Form, Input, Row, Select, Space, Spin } from 'antd'
import huHU from 'antd/lib/date-picker/locale/hu_HU'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { Dayjs } from 'dayjs'
import { FC } from 'react'
import { daysShort } from '../../utils/helpers'
import { useCreateUniqueDeparturesMutation } from './queries'


export type FormValues = {
    date: Dayjs
    cruiseId: string
    gate: DepartureModelGateEnum
    startAt: Dayjs
    description?: string
}

interface UniqueDepartureEditorProps {
    selectedDate: Dayjs
    cruises: Cruise[]
    visible: boolean
    onCreate?: () => void
    onCancel?: (e) => void
    onClose?: (e) => void
}

const requiredMessage = 'Kötelező megadni'

const requiredRule = { required: true, message: requiredMessage }

const UniqueDepartureEditor: FC<UniqueDepartureEditorProps> = ({
    visible,
    cruises,
    selectedDate,
    onCancel,
    onCreate,
    onClose,
}) => {
    const initialValues: Partial<FormValues> = {date: selectedDate}
    const departureMutation = useCreateUniqueDeparturesMutation(() => onCreate())
    const [form] = useForm<FormValues>()

    const formButtons = (
        <Row justify="end">
            <Col>
                <Space>
                    <Button
                        onClick={(_evt): void => {
                            form.resetFields()
                            onCancel(_evt)
                        }}
                    >
                        Mégsem
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            form.validateFields()
                                .then((values) => {
                                    departureMutation.mutate({
                                        cruiseId: values.cruiseId,
                                        date: values.date.format("YYYY-MM-DD"),
                                        startAt: values.startAt.format('HH:mm'),
                                        gate: values.gate as unknown as AddUniqueDepartureRequestGateEnum,
                                        description: values.description,
                                    })
                                    form.resetFields()
                                    onCreate()
                                })
                                .catch((errorInfo) => {
                                    console.log(errorInfo)
                                    // const desc = errorInfo.message || errorInfo.errorFields.map((err) => {
                                    //     return err.errors.join('/n/r')
                                    // })
                                })
                        }}
                    >
                        Beküldés
                    </Button>
                </Space>
            </Col>
        </Row>
    )

    const getDaysOptions = (): CheckboxOptionType[] => {
        return Object.entries(daysShort).map(([value, label]) => {
            return { label: label, value: value }
        })
    }
    return (
        <>
            <Spin spinning={departureMutation.isLoading}>
                <Drawer
                    title={'Dátum szerinti indulás hozzáadása'}
                    placement={'right'}
                    width={'500px'}
                    visible={visible}
                    size="large"
                    onClose={onClose}
                >
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={initialValues}
                        autoComplete="off"
                        onChange={(e) => {
                            e.target.toString()
                        }}
                    >
                        <Form.Item label={'Indulás napja'} name="date">
                            <DatePicker format={'YYYY-MM-DD'} disabled />
                        </Form.Item>
                        <Space direction="horizontal" size={'small'}>
                            <Form.Item label="Szolgáltatás" name="cruiseId" rules={[requiredRule]}>
                                <Select placeholder="Válasszon szolgáltatást" style={{ minWidth: '200px' }}>
                                    {cruises.map((cr) => (
                                        <Select.Option key={cr.id}>{cr.displayNameMap['en']}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Kapu" name="gate" rules={[requiredRule]}>
                                <Select placeholder="Válasszon kaput">
                                    <Select.Option key={DepartureModelGateEnum.GATE1}>GATE 1</Select.Option>
                                    <Select.Option key={DepartureModelGateEnum.GATE2}>GATE 2</Select.Option>
                                </Select>
                            </Form.Item>
                        </Space>
                        <Form.Item label="Indulás ideje" name="startAt" rules={[requiredRule]}>
                            <TimePicker allowClear={true} format="HH:mm" locale={huHU} />
                        </Form.Item>

                        <Form.Item label="Kiegészítő információ" name="description" rules={[]}>
                            <TextArea rows={3} />
                        </Form.Item>
                    </Form>

                    <Divider />
                    {formButtons}
                </Drawer>
            </Spin>
        </>
    )
}

export default UniqueDepartureEditor
