import React, { ComponentType, FC, useContext } from 'react'
import { Privilege, Role } from './privileges'
import _intersection from 'lodash/intersection'

export type Permission = Privilege | Role | 'SUPERUSER'

type PermissionContextType = {
    isAllowedTo: (privileges: Permission[]) => boolean
}

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider.
// The permission will not be granted unless a provider says otherwise
const defaultBehaviour: PermissionContextType = {
    isAllowedTo: () => false,
}

// Create the context
export const PermissionContext = React.createContext<PermissionContextType>(defaultBehaviour)

type Props = {
    grantedPrivileges: Permission[]
}

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
export const PermissionProvider: React.FunctionComponent<Props> = ({ grantedPrivileges, children }) => {
    const isAllowedTo = (privileges: Permission[]) => _intersection(grantedPrivileges, privileges).length > 0

    return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>
}

const usePermission = (privileges: Permission[]) => {
    const { isAllowedTo } = useContext(PermissionContext)
    return isAllowedTo(privileges)
}

type RestrictedProps = {
    to: Permission[]
    onlySU?: boolean
    fallback?: JSX.Element | string
}

export function withPermission<T>(Component: ComponentType<T>) {
    return (hocProps: T & RestrictedProps) => {
        const { to, onlySU, fallback } = hocProps

        const perms: Permission[] = onlySU && onlySU === true ? [] : ['SUPERUSER', 'ROLE_ADMIN', ...to]
        const isAllowedTo = usePermission(perms)

        if (isAllowedTo) {
            return <Component {...(hocProps as T & RestrictedProps)} />
        }

        return <>{fallback}</>
    }
}
