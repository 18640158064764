import { AppSettingDTOIdEnum } from '@hmedia/legenda-ds-api-client'
import { Button, Checkbox, Form, InputNumber, Select, Space, Spin, Tabs } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useExchangeRateSettingsQuery, useUpdateAppSettingsMutation } from './queries'

export type FormValues = {
    rate?: number
    useFetchRate: boolean
    delayInMinutes: string
}

export type Props = {
    pageTitle?: string
}

export const SETTINGSVIEW_META: ViewMeta = {
    path: '/settings',
    title: 'Árfolyam',
}

const requiredMessage = 'Kötelező megadni'
const requiredRule = { required: true, message: requiredMessage }

const SettingsView: FC<Props> = (props) => {
    const exchangeRateSettingsQuery = useExchangeRateSettingsQuery()
    const updateAppSettingsMutation = useUpdateAppSettingsMutation(() => {
        exchangeRateSettingsQuery.refetch()
        toast.success('A beállítás mentve.')
    })
    const [activeTabKey, setActiveTabKey] = useState<string>()
    const [form] = useForm<FormValues>()
    let initialValues: FormValues

    useEffect(() => {
        let datas = exchangeRateSettingsQuery.data
        form.setFieldsValue({
            rate: Number(datas?.exchangeRate),
            useFetchRate: datas?.useExternalFetch || false,
            delayInMinutes: (Number(datas?.externalFetchDelay) / 60).toString(),
        })
        return () => {}
    }, [exchangeRateSettingsQuery.data, form])

    const watchUseFetch = Form.useWatch('useFetchRate', form)

    const chkboxOnChange = (e: CheckboxChangeEvent) => {
        form.setFieldValue('rate', exchangeRateSettingsQuery.data?.externalExchangeRate)
    }

    const onSubmit = (values: FormValues) => {
        updateAppSettingsMutation.mutate([
            { id: AppSettingDTOIdEnum.EURO_EXCHANGE_RATE, value: values.rate.toString() },
            { id: AppSettingDTOIdEnum.EXTERNAL_EXCHANGE_USE_FETCH, value: `${values.useFetchRate}` },
            {
                id: AppSettingDTOIdEnum.EXTERNAL_EXCHANGE_RATE_FETCH_DELAY_IN_SECONDS,
                value: (Number(values.delayInMinutes) * 60).toString(),
            },
        ])
    }

    return (
        <Spin spinning={updateAppSettingsMutation.isLoading || exchangeRateSettingsQuery.isFetching}>
            <div style={{ width: '100%' }}>
                <h1 style={{ marginTop: '2rem' }}>Árfolyam beállítása</h1>
                <Space direction="horizontal">
                    {/* <Button onClick={() => setShowCreateRoleModal(true)} type="primary" icon={<PlusOutlined />}>
                    Új jogkör hozzáadása
                </Button> */}
                </Space>
                <Tabs
                    defaultActiveKey={'0'}
                    type="line"
                    onChange={(activeKey) => {
                        setActiveTabKey(activeKey)
                        // return queryClient.invalidateQueries(['departures', activeKey])
                    }}
                    style={{
                        marginTop: '1rem',
                        backgroundColor: 'white',
                        padding: '1rem',
                        width: '100%',
                        minHeight: '80vh',
                    }}
                    activeKey={activeTabKey}
                >
                    <Tabs.TabPane tab={`Árfolyam`} key={'0'}>
                        <Form
                            layout="inline"
                            form={form}
                            onFinish={onSubmit}
                            initialValues={initialValues}
                            autoComplete="off"
                        >
                            <Space direction="vertical">
                                <Form.Item label="EUR árfolyam" name="rate" rules={[requiredRule]}>
                                    <InputNumber
                                        addonAfter={<span>Ft</span>}
                                        min={1.0}
                                        max={999.99}
                                        size="middle"
                                        style={{ width: '120px' }}
                                        disabled={watchUseFetch}
                                    />
                                </Form.Item>
                                <Space direction="horizontal" size={'small'}>
                                    <Form.Item name="useFetchRate" valuePropName="checked">
                                        <Checkbox onChange={chkboxOnChange} disabled>
                                            Használja az árfolyamot a Legenda belső szerveréről (
                                            <strong>
                                                €1 = {exchangeRateSettingsQuery.data?.externalExchangeRate} Ft
                                            </strong>
                                            ).
                                        </Checkbox>
                                    </Form.Item>
                                    <Space direction="horizontal" size={'small'}>
                                        <Form.Item
                                            style={{ marginRight: '0' }}
                                            label="Bekérdezés"
                                            name="delayInMinutes"                                            
                                        >
                                            <Select style={{ width: '160px' }} disabled>
                                                <Select.Option value="1">1 perc</Select.Option>
                                                <Select.Option value="5">5 perc</Select.Option>
                                                <Select.Option value="15">15 perc</Select.Option>
                                                <Select.Option value="30">30 perc</Select.Option>
                                                <Select.Option value="60">1 óra</Select.Option>
                                                <Select.Option value="120">2 óra</Select.Option>
                                                <Select.Option value="120">4 óra</Select.Option>
                                                <Select.Option value="480">8 óra</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <span>elteltével.</span>
                                    </Space>
                                </Space>
                                <p style={{fontSize: '1.4em',color: 'red', fontWeight: 'bold'}}>Az automata árfolyamfrissítés egyelőre nem elérhető.</p>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={updateAppSettingsMutation.isLoading}
                                    >
                                        Mentés
                                    </Button>
                                </Form.Item>
                            </Space>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Spin>
    )
}

export default SettingsView
