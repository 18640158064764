import { DeleteOutlined, EditFilled, MenuOutlined } from '@ant-design/icons'
import { Cruise, TicketPriceDTO, TicketPriceDTOTypeEnum } from '@hmedia/legenda-ds-api-client'
import { Button, Popconfirm, Space, Spin, Switch, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { arrayMoveImmutable } from 'array-move'
import { CSSProperties, FC, useEffect, useState } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { CurrencyFormat } from './CurrencyFormat'
import './drag.css'
import styled from 'styled-components'
import { useUpdateStatusMutation, useUpdateTicketPriceIndexes } from './queries'

export const DragIcon = styled(MenuOutlined)`
    cursor: grabbing;
    cursor: -webkit-grabbing;
    color: #999;
    padding: 5px 15px;
`

const DragHandle = SortableHandle(() => <DragIcon />)

const { Title } = Typography

interface TPTCProps {
    data?: TicketPriceDTO[]
    cruises: Cruise[]
    onDelete?: (priceId: number) => void
    openEditForm?: (ticket: TicketPriceDTO) => void
    onStatusUpdate?: () => void
    style?: CSSProperties
}

const switchChildrens = {
    checkedChildren: 'IGEN',
    unCheckedChildren: 'NEM',
}

const TicketPriceTablesContainer: FC<TPTCProps> = ({
    data,
    cruises,
    onDelete,
    onStatusUpdate,
    openEditForm,
    ...props
}) => {
    const [ticketPricesByCruise, setTicketPricesByCruise] = useState<{ [cruiseId: number]: TicketPriceDTO[] }>()

    useEffect(() => {
        let unique: { [cruiseId: number]: TicketPriceDTO[] } = {}
        let distinct = []

        for (let i = 0; i < data?.length; i++) {
            if (!unique[data[i].cruise.id]) {
                distinct.push(data[i].cruise.id)
                unique[data[i].cruise.id] = data.filter((tp) => tp.cruise.id === data[i].cruise.id)
            }
        }

        setTicketPricesByCruise(unique)
        return () => {}
    }, [data])

    return (
        <Space direction="vertical" size={'large'}>
            {ticketPricesByCruise &&
                cruises.map(
                    (cru) =>
                        ticketPricesByCruise[cru.id] && (
                            <TicketPriceTable
                                cruises={cruises}
                                cruise={cru}
                                data={ticketPricesByCruise[cru.id]}
                                onDelete={onDelete}
                                onStatusUpdate={onStatusUpdate}
                                openEditForm={openEditForm}
                                style={props.style}
                            />
                        ),
                )}
        </Space>
    )
}

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)

export interface DraggableBodyProps {
    index: number
    moveRow: (dragIndex: number, hoverIndex: number) => void
    className: string
    style: CSSProperties
}

type TPTProps = {
    cruise: Cruise
} & TPTCProps

export const TicketPriceTable: FC<TPTProps> = ({
    cruises,
    cruise,
    data,
    onDelete,
    onStatusUpdate,
    openEditForm,
    ...props
}) => {
    const updateStatusMutation = useUpdateStatusMutation((data) => onStatusUpdate())
    const updateTicketPriceIndexes = useUpdateTicketPriceIndexes()
    const [priceList, setPriceList] = useState<TicketPriceDTO[]>([])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable<TicketPriceDTO>([].concat(priceList), oldIndex, newIndex)
                .filter((el) => !!el)
                .map((item, index) => ({ ...item, index: index } as TicketPriceDTO))

            // console.log('Sorted items: ', newData)
            setPriceList(newData)
            updateTicketPriceIndexes.mutate({
                requests: newData.map((price) => ({
                    id: price.id,
                    index: price.index,
                })),
            })
        }
    }

    const columns: ColumnsType<TicketPriceDTO> = [
        {
            title: 'Sorrend',
            dataIndex: 'index',
            sorter: (a, b) => a.index << b.index,
            key: 'index',
            width: 10,
            align: 'center',
            className: 'drag-visible',
            render: (index: number, ticket: TicketPriceDTO) => <DragHandle />,
        },
        {
            title: 'látszik?',
            key: 'published',
            dataIndex: 'isPublished',
            width: '100px',
            align: 'center',
            className: 'drag-visible',
            render: (isPublished: boolean, ticket: TicketPriceDTO) => {
                return (
                    <span>
                        <Switch
                            checked={isPublished}
                            onClick={(bool) => {
                                updateStatusMutation.mutate({ id: ticket.id, isPublished: bool, index: ticket.index })
                            }}
                            {...switchChildrens}
                        />
                    </span>
                )
            },
        },
        {
            title: 'Szolgáltatás',
            dataIndex: ['cruise', 'displayNameMap', 'hu'],
            key: 'cruise',
            // sorter: (a, b) => a.cruise.displayNameMap['hu'].localeCompare(b.cruise.displayNameMap['hu']),
        },
        {
            title: 'Jegy neve',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Jegytípus',
            dataIndex: 'type',
            key: 'type',
            render: (type: TicketPriceDTOTypeEnum, ticket: TicketPriceDTO) => {
                switch (type) {
                    case TicketPriceDTOTypeEnum.NORMAL:
                        return 'általános'
                    case TicketPriceDTOTypeEnum.ADULT:
                        return 'felnőtt'
                    case TicketPriceDTOTypeEnum.BPCARD:
                        return 'Budapest kártya'
                    default:
                        break
                }
            },
        },

        {
            title: 'EUR ár',
            dataIndex: 'priceEUR',
            key: 'priceEUR',
            width: 100,
            align: 'right',
            render: (value, price) => {
                return <CurrencyFormat amount={Number(price.priceEUR)} currency="EUR" />
            },
        },
        {
            title: 'HUF ár',
            dataIndex: 'priceHUF',
            key: 'priceHUF',
            width: 150,
            align: 'right',
            render: (value, price) => {
                return <CurrencyFormat amount={Number(price.priceHUF)} currency="HUF" />
            },
        },
        {
            title: 'műveletek',
            dataIndex: 'operation',
            align: 'center',
            render: (_, price) =>
                data.length >= 1 ? (
                    <Space>
                        <Popconfirm
                            title="Biztos, hogy törlöd?"
                            okText="Igen"
                            cancelText="mégsem"
                            onConfirm={(_evt) => onDelete(price.id)}
                        >
                            <Button icon={<DeleteOutlined />} type="text" />
                        </Popconfirm>
                        <Button icon={<EditFilled />} type="text" onClick={(e) => openEditForm(price)} />
                    </Space>
                ) : null,
        },
    ]

    const DraggableContainer = (props) => (
        <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
    )

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = priceList.findIndex((x) => x.index === restProps['data-row-key'])
        return <SortableItem index={index} {...restProps} />
    }

    useEffect(() => {
        setPriceList(data.sort((a,b)=> a.index - b.index))
        return () => {}
    }, [data])

    return (
        priceList && (
            <Spin spinning={updateTicketPriceIndexes.isLoading}>
                <Table
                    title={(row) => (
                        <Title style={{ marginLeft: '1rem' }} level={5}>
                            {cruise.displayNameMap['en']} jegyárak
                        </Title>
                    )}
                    rowKey="index"
                    pagination={{ hideOnSinglePage: true, pageSize: 35, position: ['bottomCenter'] }}
                    columns={columns}
                    size="middle"
                    dataSource={priceList}
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                    style={{ ...props.style }}
                />
            </Spin>
        )
    )
}

export default TicketPriceTablesContainer
