import { DatePicker } from '@leg/ds-react'
import { Modal, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useState } from 'react'
const { RangePicker } = DatePicker

export type FormValues = {
    year: Dayjs
}

interface DepartureSyncProps {
    visible: boolean
    onCreate?: (year: string) => void
    onCancel?: (e) => void
}

const DepartureSyncModal: FC<DepartureSyncProps> = ({ visible, onCancel, onCreate }) => {
    const [selectedDate, setSelectedDate] = useState(dayjs())

    const onSelect = (date: Dayjs) => {
        setSelectedDate(date)
    }

    return (
        <>
            <Modal
                title="Járatok szinkronizálása - év megadásával"
                width={540}
                visible={visible}
                cancelText="Bezárás"
                okText={'Szinkronizálás'}
                okType={'primary'}                
                onOk={(e) => onCreate(selectedDate.year().toString())}
                onCancel={onCancel}
            >
                <Space direction="horizontal">
                    Év: <DatePicker onChange={onSelect} picker="year" placeholder='Válassz évet' value={selectedDate}/>
                </Space>
            </Modal>
        </>
    )
}

export default DepartureSyncModal
