import { DepartureModelDayEnum, LocalTime, SeasonModelStartDay } from '@hmedia/legenda-ds-api-client';
import {  } from 'antd';
import React, { FC, ReactNode } from 'react';

export const formattedLocalTime = (time: LocalTime): string => {
  return time.toString().slice(0, 5)
}

export const days = {
  [DepartureModelDayEnum.MONDAY]: 'Hétfő',
  [DepartureModelDayEnum.TUESDAY]: 'Kedd',
  [DepartureModelDayEnum.WEDNESDAY]: 'Szerda',
  [DepartureModelDayEnum.THURSDAY]: 'Csütörtök',
  [DepartureModelDayEnum.FRIDAY]: 'Péntek',
  [DepartureModelDayEnum.SATURDAY]: 'Szombat',
  [DepartureModelDayEnum.SUNDAY]: 'Vasárnap',
}

export const daysShort = {
  [DepartureModelDayEnum.MONDAY]: 'Hé',
  [DepartureModelDayEnum.TUESDAY]: 'Ke',
  [DepartureModelDayEnum.WEDNESDAY]: 'Sze',
  [DepartureModelDayEnum.THURSDAY]: 'Csü',
  [DepartureModelDayEnum.FRIDAY]: 'Pé',
  [DepartureModelDayEnum.SATURDAY]: 'Szo',
  [DepartureModelDayEnum.SUNDAY]: 'Va',
}

export { }
