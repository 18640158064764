import { MediaFileDTO, UploadFileResponse } from "@hmedia/legenda-ds-api-client";
import { useMutation, useQuery } from "react-query";
import API from "../../services/DSApiService";

const uploadFile = async (file: FormData) => (await API.uploadFile(file)).data

const getMediaFiles = async () => (await API.getMediaFiles()).data

const createMediaFile = async (params: CreateMediaFileMutation) =>
  (await API.createMediaFile(params.title, params.filename)).data

const updateMediaFile = async (params: UpdateMediaFileMutation) =>
  (await API.createMediaFile(params.title, params.filename)).data



export const useMediaFilesQuery = () => useQuery("mediafiles", () => getMediaFiles())

export const useUploadFileMutation = (onSuccess?: (data: UploadFileResponse) => void, onError?: (error) => void) =>
  useMutation((file: FormData) => uploadFile(file))

export const useCreateMediaFileMutation = (onSuccess?: (data: MediaFileDTO) => void, onError?: (error) => void) =>
  useMutation((params: CreateMediaFileMutation) => createMediaFile(params))

export const useUpdateMediaFileMutation = (onSuccess?: (data: MediaFileDTO) => void, onError?: (error) => void) =>
  useMutation((params: UpdateMediaFileMutation) => updateMediaFile(params))



type CreateMediaFileMutation = { title: string, filename: string }
type UpdateMediaFileMutation = CreateMediaFileMutation