import { CloseOutlined } from '@ant-design/icons'
import {
    RunnableContentDTO,
    SendActualityToContentRequestContentTypesEnum
} from '@hmedia/legenda-ds-api-client'
import { Modal, Switch, Table, TableColumnType } from 'antd'
import { FC, useState } from 'react'
import { useRunnableContentQuery } from '../screens/queries'
import { switchChildrens } from '../uniqueDepartures/UniqueDeparturesView'
import { getInformTypeName, InformDTO } from './models'
import { useSendActualityToContentMutation } from './queries'

type Props = {
    visible: boolean
    inform?: InformDTO
    onClose: (e) => void
}

export const ContentSelectorModal: FC<Props> = ({ visible, onClose, ...props }) => {
    const actualityAwareContentQuery = useRunnableContentQuery()
    const [inform, setInform] = useState(props.inform)
    const sendActualityToContentMutation = useSendActualityToContentMutation((data) => setInform(data))

    const onChangeOne = (contentTypes: SendActualityToContentRequestContentTypesEnum[], isDisplaying: boolean) => {
        sendActualityToContentMutation.mutate({
            informId: inform?.id?.toString(),
            contentTypes: contentTypes,
            toDisplay: isDisplaying,
        })
    }

    const tableCols: TableColumnType<RunnableContentDTO>[] = [
        {
            title: 'Tartalom neve',
            dataIndex: 'title',
        },
        {
            title: 'megjelenítve?',
            dataIndex: 'title',
            align: 'right',
            width: '130px',
            render: (title: string, content, idx) => {
                const isDisplaying =
                    inform !== undefined &&
                    inform !== null &&
                    Object.keys(inform.contentMap).includes(content.contentType)
                return (
                    <Switch
                        {...switchChildrens}
                        loading={actualityAwareContentQuery.isLoading}
                        checked={isDisplaying}
                        onChange={(value) => {
                            onChangeOne(
                                [content?.contentType as undefined as SendActualityToContentRequestContentTypesEnum],
                                value,
                            )
                        }}
                    />
                )
            },
        },
    ]

    const informTypeName = getInformTypeName(inform?.type)

    return (
        <Modal
            title={`${informTypeName} megjelenítése az alábbi tartalmaknál`}
            visible={visible}
            width={460}
            closeIcon={<CloseOutlined />}
            closable={true}
            onCancel={onClose}
            footer={null}
            maskClosable={true}
        >
            <p>
                Kiválasztott {informTypeName.toLowerCase()}: <strong>"{inform.name}"</strong>
            </p>
            <Table
                pagination={false}
                rowKey={(content) => {
                    return content.contentType
                }}
                scroll={{ y: '60vh' }}
                columns={tableCols}
                size={'small'}
                loading={actualityAwareContentQuery.isLoading}
                dataSource={actualityAwareContentQuery?.data?.filter((content) => content.canHandleActuality === true)}
            />
        </Modal>
    )
}
