import { CreateUpdateInformRequest, DeleteInformRequest, InformModel, SendActualityToContentRequest, SendInformToScreenRequest } from "@hmedia/legenda-ds-api-client";
import { useMutation, useQuery } from "react-query";
import API from "../../services/DSApiService";
import { InformDTO, InformTypeConstantDTO } from "./models";

const searchInformByType = async (type: InformTypeConstantDTO) => (await API.searchInformBy(type)).data.map(model => convertInformModelToDTO(model))
const createUpdateInform = async (request: CreateUpdateInformRequest) => convertInformModelToDTO((await API.createUpdateInform(request)).data)
const sendInfoToScreen = async (request: SendInformToScreenRequest) => (await API.sendInfoToScreen(request))
const sendActualityToContent = async (request: SendActualityToContentRequest) => convertInformModelToDTO((await API.sendActualityToContent(request)).data)
const getActualityAwareContents = async () => (await API.getRunnableContentList()).data
const deleteInforById = async (request: DeleteInformRequest) => (await API.deleteInformById(request))

export const useSearchInformByTypeQuery = (type: InformTypeConstantDTO) => useQuery(['informByType', type], () => searchInformByType(type))
export const useCreateUpdateInformMutation = (onSuccess?: (data: InformDTO) => void, onError?: (error) => void) =>
  useMutation((request: CreateUpdateInformRequest) => createUpdateInform(request), { onSuccess: onSuccess, onError: onError })
export const useSendInfoToScreenMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((request: SendInformToScreenRequest) => sendInfoToScreen(request), { onSuccess: onSuccess, onError: onError })
export const useSendActualityToContentMutation = (onSuccess?: (data: InformDTO) => void, onError?: (error) => void) =>
  useMutation((request: SendActualityToContentRequest) => sendActualityToContent(request), { onSuccess: onSuccess, onError: onError })
export const useDeleteInforByIdMutation = (onSuccess?: () => void, onError?: (error) => void) =>
  useMutation((request: DeleteInformRequest) => deleteInforById(request), { onSuccess: onSuccess, onError: onError })
export const useActualityAwareContentQuery = () => useQuery(['actualityAwareContents'], () => getActualityAwareContents())



const convertInformModelToDTO = (model: InformModel): InformDTO => ({
  id: Number(model.id),
  name: model.name,
  type: model.type,
  title: model.title,
  contentJSON: model.contentJSON,
  contentHTML: model.contentHTML,
  updatedAt: model.updatedAt,
  isTodayShown: model.isTodayShown,
  contentMap: model.contentMap
})